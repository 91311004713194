
















































































































































































import Vue, { PropType } from "vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  UPDATE_USER_PROFILE,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import DatePicker from "@/components/shared/view_profile/DatePicker.vue";
import moment from "moment";
import { scoreUtils } from "@/utils";
import { clean_string } from "@/utils/global";

export default Vue.extend({
  name: "CrudEducationDetails",
  components: { DatePicker },
  data() {
    return {
      degree_title: "",
      organization_name: "",
      start_date: "",
      end_date: "",
      details: "",
      edu_form: false,
      required_rule: [
        (value: string) => !!value || this.$t("rules.field-required").toString()
      ],
      allowed_months: [] as Date[],
      future_dates_not_allowed: [
        (value: string) => {
          return (value &&
            moment(value).month() > moment().month() &&
            moment(value).year() >= moment().year()) ||
            (moment(value).month() < moment().month() &&
              moment(value).year() > moment().year())
            ? this.$t("candidate.profile.future-dates").toString()
            : true;
        }
      ],
      start_dates_not_allowed: [] as ((value: string) => boolean | string)[]
    };
  },
  props: {
    type: {
      type: String as PropType<CrudEnum>,
      required: true
    },
    education: {
      type: Object as PropType<Candidates.EducationDetails>
    },
    index: {
      type: String
    }
  },
  created() {
    if (this.type !== CrudEnum.ADD && this.index !== "-1") {
      this.degree_title = this.education?.degree_title;
      this.organization_name = this.education?.organization_name;
      // Time Period
      if (clean_string(this.education?.time_period) !== "n/a - n/a") {
        const time = this.education.time_period.split("-");
        if (time.length === 2) {
          this.start_date = clean_string(time[0]) !== "n/a" ? time[0] : "";
          this.end_date = clean_string(time[1]) !== "n/a" ? time[1] : "";
        }
      }

      this.details = this.education?.details;
    }
    this.start_dates_not_allowed = [
      (value: string) => {
        return (value &&
          this.start_date &&
          moment(value).month() <= moment(this.start_date, "MM/YYYY").month() &&
          moment(value).year() <= moment(this.start_date, "MM/YYYY").year()) ||
          (moment(value).month() > moment(this.start_date, "MM/YYYY").month() &&
            moment(value).year() < moment(this.start_date, "MM/YYYY").year())
          ? this.$t("candidate.profile.end-date").toString()
          : true;
      }
    ];
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_profile: USER_PROFILE,
      auth_loading: AUTH_LOADING
    })
  },
  methods: {
    ...mapActions("auth", {
      update_user_profile: UPDATE_USER_PROFILE
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    select_start_date(date: string) {
      this.start_date = date;
    },
    select_end_date(date: string) {
      this.end_date = date;
    },
    cancel_action() {
      this.degree_title = "";
      this.organization_name = "";
      this.start_date = "";
      this.end_date = "";
      this.details = "";
      this.edu_form = false;
      this.$emit("cancel", false);
    },
    async update_details() {
      const payload = this.user_profile;
      const data_obj = {
        degree_title: this.degree_title,
        time_period: this.start_date + " - " + this.end_date,
        organization_name: this.organization_name,
        details: this.details
      };
      if (this.type === CrudEnum.UPDATE) {
        payload.profile.education_detailed[this.index] = data_obj;
      } else if (this.type === CrudEnum.ADD) {
        // Add new data at first index and incrementing other keys by 1
        payload.profile.education_detailed = {
          "1": data_obj,
          ...Object.fromEntries(
            Object.entries(payload.profile.education_detailed).map(([k, v]) => [
              parseInt(k) + 1,
              v
            ])
          )
        };
      } else if (this.type === CrudEnum.DELETE) {
        delete payload.profile.education_detailed[this.index];
        // Decrementing all keys by 1
        payload.profile.education_detailed = Object.fromEntries(
          Object.entries(payload.profile.education_detailed).map(([k, v]) => {
            const newKey =
              parseInt(k) > parseInt(this.index)
                ? parseInt(k) - 1
                : parseInt(k);
            return [newKey, v];
          })
        );
      }
      const response = await this.update_user_profile(payload);
      if (response) {
        this.root_notification(
          this.$t("candidate.profile.profile-update").toString()
        );
      } else
        this.root_error(this.$t("candidate.profile.failed-update").toString());
      scoreUtils.clearProfileScore();
      this.edu_form = false;
      this.$emit("cancel", false);
      this.degree_title = "";
      this.organization_name = "";
      this.start_date = "";
      this.end_date = "";
      this.details = "";
    }
  }
});
