












































































































import Vue, { PropType } from "vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  UPDATE_USER_PROFILE,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { scoreUtils } from "@/utils";

export default Vue.extend({
  name: "CrudPersonalityDetails",
  data() {
    return {
      details: "",
      personality_form: false,
      required_rule: [
        (value: string) => !!value || this.$t("rules.field-required").toString()
      ]
    };
  },
  props: {
    type: {
      type: String as PropType<CrudEnum>,
      required: true
    },
    data: {
      type: Object as PropType<Candidates.PersonalityDetails>
    },
    index: {
      type: String
    }
  },
  created() {
    if (this.type !== CrudEnum.ADD) {
      this.details = this.data?.details;
    }
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_profile: USER_PROFILE,
      auth_loading: AUTH_LOADING
    })
  },
  methods: {
    ...mapActions("auth", {
      update_user_profile: UPDATE_USER_PROFILE
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    cancel_action() {
      this.$emit("cancel", false);
    },
    async update_details() {
      const payload = this.user_profile;
      const data_obj = {
        details: this.details
      };
      if (this.type === CrudEnum.UPDATE) {
        payload.profile.personality_detailed[this.index] = data_obj;
      } else if (this.type === CrudEnum.ADD) {
        if (!payload.profile.personality_detailed) {
          payload.profile.personality_detailed = {
            "1": data_obj
          };
        } else {
          // Add new data at first index and incrementing other keys by 1
          payload.profile.personality_detailed = {
            "1": data_obj,
            ...Object.fromEntries(
              Object.entries(payload.profile.personality_detailed).map(
                ([k, v]) => [parseInt(k) + 1, v]
              )
            )
          };
        }
      } else if (this.type === CrudEnum.DELETE) {
        delete payload.profile.personality_detailed[this.index];
        // Decrementing all keys by 1
        payload.profile.personality_detailed = Object.fromEntries(
          Object.entries(payload.profile.personality_detailed).map(([k, v]) => {
            const newKey =
              parseInt(k) > parseInt(this.index)
                ? parseInt(k) - 1
                : parseInt(k);
            return [newKey, v];
          })
        );
      }
      const response = await this.update_user_profile(payload);
      if (response) {
        this.root_notification(
          this.$t("candidate.profile.profile-update").toString()
        );
      } else
        this.root_error(this.$t("candidate.profile.failed-update").toString());
      scoreUtils.clearProfileScore();
      this.personality_form = false;
      this.cancel_action();
    }
  }
});
